import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Spinner, Tab } from "react-bootstrap";
import styles from "./TaskPop.module.scss";

// img
import BTNC from "../../../assets/images/diamond.png";
import { useDispatch, useSelector } from "react-redux";
import { tasksSlice } from "../../../lib/store/tasks/slice";
import {
  completeTaskApi,
  taskClickedApi,
  taskStatusApi,
} from "../../../lib/store/actions";
import { generateTelInviteLink, wait } from "../../../helper/common";
import { useNavigate } from "react-router-dom";

const TaskPop = ({ taskPop, setTaskPop, onFinish }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabledClaim, setDisabledClaim] = useState(false);
  const { user } = useSelector(({ Auth }) => Auth);
  const { taskDetails, loadingCompleteTask, loadingUpdateTask } = useSelector(
    ({ Tasks }) => Tasks
  );

  useEffect(() => {
    if (taskDetails?.type === "instant") {
      if(!taskDetails?.hasClicked){
        setDisabledClaim(true);
      }else{
        setDisabledClaim(false)
      }
    }
  }, [taskDetails]);

  useEffect(() => {
    if (!taskDetails?.hasOpened) {
      dispatch(
        taskStatusApi({
          data: { _id: taskDetails?._id },
          callBack: (err, res) => {
            if (err) {
              console.error("API error:", err);
              return;
            }

            // Updated task details
            const updatedTask = { ...taskDetails, hasOpened: true };

            // Dispatch to update taskDetails
            dispatch(tasksSlice.actions.setTaskDetails({ data: updatedTask }));

            // Dispatch to update the task list
            dispatch(tasksSlice.actions.updateTaskList({ data: updatedTask }));
          },
        })
      );
    }
  }, [taskDetails]);

  const handleTask = () => {
    setTaskPop(!taskPop);
  };

  // useEffect(() => {
  //   return () => dispatch(tasksSlice.actions.setTaskDetails({ data: null }));
  // }, []);

  const handleCheck = () => {
    if (!taskDetails?._id) return;
    if (loadingCompleteTask) return;
    // if (taskDetails?.type === "instant") {
    //   openLinks(taskDetails);
    // }
    wait(300);
    dispatch(
      completeTaskApi({
        data: { _id: taskDetails?._id },
        callBack: (err, res) => {
          if (err) return;
          onFinish && onFinish(res?.data);
        },
      })
    );
  };

  const openLinks = (taskDetails) => {
    if (loadingUpdateTask) return;

    let link = `${taskDetails?.link}${
      taskDetails?.type === "friend" ? user?.telegram_id : ""
    }`;
    if (taskDetails?.type === "friend") {
      link = generateTelInviteLink(link);
    }
    if (taskDetails?.type === "instant"  && !taskDetails.hasClicked) {
      dispatch(
        taskClickedApi({
          data: { _id: taskDetails?._id },
          callBack: (err, res) => {
            if (err) return;
            // onFinish && onFinish(res?.data);
            const updatedTask = {
              ...taskDetails,
              hasClicked: res.data.hasClicked,
            };
            dispatch(
              tasksSlice.actions.setTaskDetails({
                data: updatedTask,
              })
            );

            dispatch(tasksSlice.actions.updateTaskList({ data: updatedTask }));
          },
        })
      );
    }
    // if (window.Telegram?.WebApp && taskDetails?.type === "instant") {
    //   window.Telegram.WebApp.openLink(link);
    //   setDisabledClaim(false);
    // } else {
    //   window.open(link, "_blank"); // Fallback for non-Telegram environments
    //   setDisabledClaim(false);
    // }
  };

  console.log(taskDetails, "DETAILS");

  return (
    <>
      <Modal
        show={taskPop}
        onHide={handleTask}
        backdrop="true"
        centered
        className={`${styles.TaskPop} bottomPop`}
        keyboard={false}
      >
        <div className="modalWrpper">
          <Modal.Body className="pb-5">
            <Button
              onClick={handleTask}
              variant="transparent"
              className="border-0 p-0 position-absolute"
              style={{ top: 15, right: 15 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.0024 26.0048C20.1834 26.0048 26.0048 20.1834 26.0048 13.0024C26.0048 5.82137 20.1834 0 13.0024 0C5.82137 0 0 5.82137 0 13.0024C0 20.1834 5.82137 26.0048 13.0024 26.0048ZM13.0062 14.1485L18.1747 19.3251L19.3205 18.1711L14.152 13.0026L19.3205 7.83412L18.1747 6.68827L13.0062 11.8568L7.83767 6.68015L6.6837 7.83412L11.8603 13.0026L6.6837 18.1711L7.83767 19.3251L13.0062 14.1485Z"
                  fill="#506380"
                />
              </svg>
            </Button>
            <div className="py-2 text-center">
              <img
                src={taskDetails?.icon}
                alt=""
                className="img-fluid object-fit-contain"
                style={{ height: 80 }}
              />
              <h4 className="m-0 fw-bold text-white py-2">
                {/* {taskDetails?.awardType === "coins" ?  */}
                {taskDetails?.title}
                {/* taskDetails?.awardData?.name} {" "} */}
              </h4>
              <p className="text-center fw-bold text-white fs-4">
                {taskDetails?.type === "friend"
                  ? `${user?.inviteCount || 0}/${taskDetails?.count}`
                  : " "}
              </p>
              <div className="py-2 mt-2">
                <Button
                  onClick={() => {
                    openLinks(taskDetails);
                  }}
                  rel="noopener noreferrer"
                  style={{ maxWidth: 229, height: 40, background: "#1ABDE2" }}
                  className="d-inline-flex align-items-center fw-bold justify-content-center commonBtn border-0"
                >
                  {loadingUpdateTask && (
                    <Spinner className={`${styles.mSpinner} mx-2`} size="sm" />
                  )}
                  {taskDetails?.upperButtonText}
                </Button>
              </div>
              <div className="py-2 mt-3">
                <div className="d-flex align-items-center gap-10 justify-content-center">
                  <span className="icn">
                    <img
                      src={
                        taskDetails?.awardType === "coins"
                          ? BTNC
                          : taskDetails?.awardData?.icon
                      }
                      style={{ height: 30 }}
                      alt=""
                      className="img-fluid object-fit-contain"
                    />
                  </span>
                  <h4 className="m-0 fw-sbold text-white">
                    +
                    {taskDetails?.awardType === "coins"
                      ? taskDetails?.coins
                      : taskDetails?.awardData?.quantity +
                          " " +
                          taskDetails?.awardType ===
                        "crypto"
                      ? taskDetails?.awardType?.symbol
                      : ""}
                  </h4>
                </div>
              </div>
            </div>
            <div className="mt-3 text-center">
              <Button
                disabled={disabledClaim}
                style={{ maxWidth: 229, height: 45 }}
                onClick={() => handleCheck()}
                className="d-inline-flex align-items-center fw-bold justify-content-center w-100 commonBtn border-0"
              >
                {loadingCompleteTask && (
                  <Spinner className={`${styles.mSpinner} mx-2`} size="sm" />
                )}

                {taskDetails?.buttonText}
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default TaskPop;
